/// <reference path="../references.ts"/>
var awoMain = {
    swiperHoverOneInstance: null,
    swiperHoverTwoInstance: null,
    init: function () {
        awoMain.initSwiperHome();
        awoMain.initSelectizeHome();
        awoMain.initSwiperHoverArea();
        awoMain.triggerHoverAreaSwiper();
        awoMain.initNewsEventsMobileSwipers();
        awoMain.initResourceMobileSwiper();
    },
    /**
     * Initialise swiper on homepage
     */
    initSwiperHome: function () {
        var swiper = new Swiper('.awo-home-hero-swiper', {
            speed: 500,
            autoplay: {
                delay: 5000,
            },
            pagination: {
                el: '.awo-home-hero-swiper-pagination',
                clickable: true,
            }
        });
    },
    /**
     * Initialise selectize dropdown on homepage
     */
    initSelectizeHome: function () {
        var selectize = $('select.awo-looking-for__dropdown').selectize({
            create: false,
            onInitialize: function () {
                var button = document.querySelector('.awo-looking-for__button > a');
                button.setAttribute('href', this.items[0]);
            },
            onChange: function (value) {
                var button = document.querySelector('.awo-looking-for__button > a');
                button.setAttribute('href', value);
            }
        });
    },
    /**
     * Init hover area swiper sliders
     */
    initSwiperHoverArea: function () {
        awoMain.swiperHoverOneInstance = new Swiper('.awo-hover-area-slide-swiper', {
            watchSlidesProgress: true,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            allowTouchMove: false,
        });
        awoMain.swiperHoverTwoInstance = new Swiper('.awo-hover-area__content-left-slider-swiper', {
            autoplay: {
                delay: 4000,
            },
            autoHeight: true,
            thumbs: {
                swiper: awoMain.swiperHoverOneInstance
            },
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            pagination: {
                el: '.awo-hover-area-slide-swiper-pagination',
                clickable: true,
            },
            on: {
                slideChange: function (swiper) {
                    var links = document.querySelectorAll('ul.awo-hover-area__content-right--swiper-trigger > li');
                    var activeLink = document.querySelector('li[data-index="' + swiper.realIndex + '"]');
                    links.forEach(function (el) {
                        el.classList.remove('is-active');
                    });
                    activeLink.classList.add('is-active');
                },
            },
        });
    },
    /**
     * Trigger hover area swiper sliders
     */
    triggerHoverAreaSwiper: function () {
        var links = document.querySelectorAll('ul.awo-hover-area__content-right--swiper-trigger > li');
        links.forEach(function (el) {
            el.addEventListener('mouseenter', function (e) {
                // Change slide on mouse hover
                awoMain.swiperHoverTwoInstance.slideTo(this.getAttribute('data-index'));
                links.forEach(function (link) {
                    link.classList.remove('is-active');
                });
                this.classList.add('is-active');
            });
        });
    },
    /**
     * News and Events alpine instance
     * @returns Object
     */
    newsAndEventsAlpineJs: function () {
        return {
            tabs: {
                viewAll: true,
                news: false,
                events: false,
            },
            show: function (id) {
                for (var tab in this.tabs) {
                    this.tabs[tab] = false;
                }
                this.tabs[id] = true;
            },
        };
    },
    /**
     * Init news and events mobile swipers
     */
    initNewsEventsMobileSwipers: function () {
        awoMain.swiperHoverOneInstance = new Swiper('.awo-news-events__container--grid--mobile-swiper', {
            spaceBetween: 20,
            slidesPerView: 1,
            breakpoints: {
                480: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                }
            }
        });
    },
    /**
     * Init resource mobile swiper
     */
    initResourceMobileSwiper: function () {
        awoMain.swiperHoverOneInstance = new Swiper('.awo-resource__container--mobile-swiper', {
            spaceBetween: 20,
            pagination: {
                el: '.awo-resource__container--mobile-swiper-pagination',
                clickable: true,
            }
        });
    },
};
